import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Reviews list`}</h4>
    <p>{`Open the list by clicking on the button at the left edge of the screen.`}</p>
    <br />
    <p>{`Switch to the "Reviews" tab.`}</p>
    <Image src="features/reviewslistopen.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <p>{`The list shows all reviews in your selected map section. By default they are
sorted by date and you see the latest review at the top.`}</p>
    <br />
    <p>{`The best rating is "Epic" with 5 of 5 stars. The worst is "Bad" with 1 of 5
stars. Click on the bold activity name to open the activity page and find out
more.`}</p>
    <br />
    <Link to="/features/reviews" mdxType="Link">
  See how to review an activity here.
    </Link>
    <br />
    <br />
    <h4>{`Filter`}</h4>
    <p>{`Open the filter by clicking on the filter button at the very left of the
search box.`}</p>
    <br />
    <p>{`You can filter the reviews by ratings. By default all ratings are activated.
Click on the rating you want to unselect and hide.`}</p>
    <Image src="features/reviewslistfilter.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <h4>{`Show reviews on the map`}</h4>
    <p>{`When the "Reviews" tab is activated, the reviews are shown on the map. Close
the list to get back to the map by clicking on the "x" button at the top right.`}</p>
    <br />
    <p>{`Now you see circles on each trail head. The color indicates the average
rating, the diameter indicates the number of reviews.`}</p>
    <br />
    <p>{`Click on a circle to see the individual reviews.`}</p>
    <Image src="features/reviewsmap.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/userpage" mdxType="Link">
  ← User page
    </Link>
    <Link className="float-right" to="/features/reviews" mdxType="Link">
  Review an activity →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      